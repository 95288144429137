<template>
    <button v-if="info.handler" class="btn-generic" @click="info.handler">
        <span v-if="info.icon" class="btn-generic__image"
            ><img :src="info.icon" :alt="info.iconAlt || 'icon'"
        /></span>
        <p class="btn-generic__text">{{ info.text }}</p>

        <img
            v-if="loading"
            src="/assets/png/loading-white.png"
            height="17"
            width="17"
            alt="icon"
            class="spin"
        />
    </button>
    <NuxtLink v-else class="btn-generic" rel="follow" :to="info.link">
        <span v-if="info.icon" class="btn-generic__image"
            ><img :src="info.icon" :alt="info.iconAlt || 'icon'"
        /></span>
        <p class="btn-generic__text">{{ info.text }}</p>
    </NuxtLink>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    name: 'BtnGeneric',
    props: {
        loading: { type: Boolean, default: false },
        info: {
            required: true,
            type: Object as PropType<{
                text: string
                link: string
                handler: () => null
                icon: string
                iconAlt: string
            }>,
        },
    },
})
</script>

<style lang="postcss" scoped>
.btn-generic {
    @apply flex items-center justify-center space-x-3 h-10 px-5 border;
    &__image {
        @apply block w-5 h-5;
        img {
            @apply w-full h-full object-contain;
        }
    }
}
</style>
