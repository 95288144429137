<template>
    <div class="modal-confirm">
        <slot></slot>
        <h3 class="modal-confirm__title" >{{ info.title }}</h3>
        <p class="modal-confirm__text" > {{ info.text }}</p>
        <div class="modal-confirm__buttons">
            <BtnGeneric
                v-if="info.cancel"
                class="modal-confirm__cancel-button"
                :info="{ text: info.cancel.text, handler: cancelHandler }"
            />
            <BtnGeneric
                v-if="info.continue"
                class="modal-confirm__continue-button"
                :info="{
                    text: info.continue.text,
                    handler: continueHandler,
                }"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'

const props = defineProps({
    info: {
        type: Object as PropType<{
            title: string
            text: string
            continue?: {
                handler: () => any
                text: string
            }
            cancel?: {
                handler: () => any
                text: string
            }
        }>,
        required: true,
    },
})

const emit = defineEmits(['close'])

const cancelHandler = async () => {
    emit('close')
    await nextTick()
    props.info.cancel?.handler()
}
const continueHandler = async () => {
    emit('close')
    await nextTick()
    props.info.continue?.handler()
}
</script>

<style lang="postcss" scoped>
.modal-confirm {
    @apply relative bg-white px-5 py-10 max-w-md;
    &__title {
        @apply text-center font-medium text-lg text-gray-800 mb-5;
    }
    &__text {
        @apply  text-lg text-gray-800 mb-10;
    }
    &__buttons {
        @apply flex justify-center space-x-5;
    }
    &__cancel-button {
        @apply border border-black lg:hover:bg-black lg:transition-colors lg:hover:text-white;
    }
    &__continue-button {
        @apply font-medium text-white border-site-primary bg-site-primary;
    }
}
</style>
